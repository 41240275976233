<template>
  <div class="userInfo">
    <div class="commit">
      <div class="commit_conent">
        <div class="user_info_box no_select">
          <div class="user_info_box_left">
            <el-image class="head-sculpture" :src="userInfo.header">
              <div slot="error" class="image-slot">
                <img class="head-sculpture" src="@/assets/img/tou.png">
              </div>
            </el-image>

            <div class="user_info">
              <div class="user_name_box">
                <span class="user_name "
                      :class="{'is_vipColor': userInfo.isHy}">{{userInfo.name || '请设置昵称'}}</span>
                <!-- <i v-if="userInfo.isHy"
                   class="iconfont icon-a-wangguaniconqiepian3x is_vipColor"></i> -->
                <span class="user_badge"
                      v-if="userInfo.isHy || $store.getters.userIpInfo.partnerId">
                  <img v-if="userInfo.isHy"
                       src="https://img.infox-med.com/webImg/infoPc/icon/vip_icon.png" alt="">
                  <span v-if="$store.getters.userIpInfo.partnerId">IP 认证</span>
                </span>
              </div>

              <div class="vip_type">
                <div class="user_points" v-if="userInfo.signScore">
                  <span class="team_name">当前积分: {{userInfo.signScore}}</span>
                </div>

                <div class="tema_info" v-if="userInfo.enterpriseName">
                  <img src="@/assets/img/userInfo/team_icon1.png" alt="">
                  <span class="team_name">
                    {{userInfo.enterpriseName}}
                  </span>
                </div>

                <div class="vip_time">
                  {{$store.getters.userIpInfo.partnerId? `IP有效期至${$store.getters.userIpInfo.vipExpiredTime}` :  warningTime(userInfo.vipStatus)}}
                </div>
              </div>
            </div>

          </div>
          <div class="user_info_box_rigth">
            <div @click="isEditBox" class="edit_btn">
              个人资料
            </div>
          </div>
        </div>

        <div class="tab_box my_card no_select">
          <el-menu :default-active="$route.path" router class="el-menu-vertical-demo"
                   background-color="#FFF" text-color="#666" active-text-color="#4B639F"
                   mode="horizontal">
            <el-menu-item v-for="route in myRouterList" :key="route.path"
                          :index="'/userInfo/' + route.path">
              <i class="iconfont" :class="route.meta.icon"></i>
              <span slot="title">{{route.meta.title}}</span>
            </el-menu-item>
          </el-menu>
        </div>

        <div class="views_box">
          <router-view></router-view>
        </div>
      </div>
      <!-- 编辑用户信息盒子 -->
      <user-info-edit ref="edit" :isShow.sync="isShow" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import userInfoEdit from './components/userInfoEdit.vue'
export default {
  components: {
    userInfoEdit
  },
  data () {
    return {
      vipType: ['可前往APP开通会员以享受更多服务', '会员剩余', '会员即将在', '会员已过期'],
      isShow: false,
      myRouterList: []
    }
  },
  methods: {
    warningTime (vipStatus) {
      const stateMap = {
        0: '可前往APP开通会员以享受更多服务',
        1: this.userInfo.userExpire,
        2: `会员即将在${this.userInfo.expireDays}天后过期`,
        3: '会员已过期'
      }
      return stateMap[vipStatus]
    },
    isEditBox () {
      this.isShow = true
      this.$nextTick(() => {
        this.$refs.edit.setEditBox()
      })
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
    this.myRouterList = this.$router.options.routes.filter(item => item.name === 'userInfo')[0].children
  }
}
</script>
<style lang="less" scoped>
.userInfo {
  color: #000;

  .commit {
    min-height: 856px;
    background-color: #f5f7fb;
    .commit_conent {
      width: 1200px;
      min-height: 700px;
      margin: 0 auto;
      padding: 30px 0 0;
      background-color: #f5f7fb;
      .user_info_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100px;
        background-color: #fff;
        padding: 20px 40px;
        box-shadow: 0 0 6px 0 #e8e8e8;
        border-radius: 5px;
        .user_info_box_left {
          min-width: 50%;
          position: relative;
          display: flex;
          align-items: center;
          .el-image {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-right: 20px;
            object-fit: cover;
            .head-sculpture {
              width: 100%;
              height: 100%;
            }
          }
          .user_name_box {
            position: relative;
            bottom: -5px;
            display: flex;
            align-items: center;
            .user_name {
              font-size: 24px;
              font-weight: 600;
              color: #000;
            }
            i {
              font-size: 18px;
              margin-left: 8px;
            }
            .is_vipColor {
              color: #f7ae53;
            }
            .user_badge {
              display: flex;
              align-items: center;
              margin-left: 10px;
              padding: 3px 10px;
              border-radius: 9999px;
              border: 1px solid #f7ae53;
              color: #f7ae53;
              img {
                position: relative;
                top: -2px;
                width: 15px;
              }
              span {
                margin-left: 5px;
                font-weight: 600;
              }
            }
          }

          .vip_type {
            position: relative;
            bottom: -12px;
            display: flex;
            align-items: center;
            color: #9195a3;
            font-size: 12px;
            .user_points {
              margin-right: 50px;
            }
            .team_name {
              background: linear-gradient(135deg, #84bdfa, #4d8cf4);
              background-clip: text;
              color: transparent;
              font-weight: 600;
            }
            .tema_info {
              display: flex;
              align-items: center;
              margin-right: 50px;
              img {
                width: 14px;
                margin-right: 2px;
              }
            }
          }
        }
        .user_info_box_rigth {
          .edit_btn {
            width: 108px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            border: 1px solid #4b639f;
            background-color: #fff;
            color: #4b639f;
            border-radius: 3px;
          }
        }
      }
      .tab_box {
        position: sticky;
        top: 52px;
        width: 100%;
        height: 60px;
        padding: 0 20px;
        z-index: 99;
        .el-menu {
          border-bottom: 0;
        }
        .el-menu-item {
          // background-color: #fff !important;
          font-size: 16px;
        }
        .el-menu-item:hover {
          background-color: #d7dce9 !important;
        }
        .is-active {
          font-weight: 700;
          .iconfont {
            font-weight: 400;
          }
        }
        .iconfont {
          margin-right: 10px;
        }
      }
      .views_box {
        padding: 20px 0;
      }
    }
  }
}
</style>
