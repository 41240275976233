<template>
  <!--  -->
  <div ref="bgbox" v-if="isShow" class="user_info_edire" @click="hideUserInfoEdir">
    <div ref="edit_box" @click.stop class="edit_box loading"
         :style="`transform-origin: ${origin.x} ${origin.y};`">

      <div class="edit_top_box">
        如需修改个人信息请前往app
        <i @click="hideUserInfoEdir" class="el-icon-close"></i>
      </div>
      <div class="edit_box_content">
        <ul class="edit_box_content_left">
          <li v-for="item in optionsList" :key="item.id"
              :class="{'is_option':isOption=== item.id }">
            <span>{{item.value}}</span>
          </li>
        </ul>
        <div class="edit_box_content_right">
          <user-info-component ref="userinfocomponent" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userInfoComponent from './user.vue'
export default {
  components: {
    userInfoComponent
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    origin: {
      type: Object,
      default: () => ({ x: '150%', y: '0' })
    }
  },
  data () {
    return {
      optionsList: [
        {
          id: 1,
          value: '个人资料'
        }
      ],
      isOption: 1
    }
  },
  methods: {
    setEditBox () {
      setTimeout(() => {
        const body = document.querySelector('body')
        const box = this.$refs.edit_box
        const bg = this.$refs.bgbox
        body.style.overflow = 'hidden'
        bg.style.opacity = '1'
        box.style.transform = 'scale(1)'
        box.style.opacity = '1'
      }, 100)
    },
    hideUserInfoEdir () {
      const body = document.querySelector('body')
      const box = this.$refs.edit_box
      const bg = this.$refs.bgbox
      body.style.overflow = 'visible'
      bg.style.opacity = '0'
      box.style.transform = 'scale(0.1)'
      box.style.opacity = '0'
      setTimeout(() => {
        this.$emit('update:isShow', false)
      }, 100)
    }
  }
}
</script>
<style lang="less" scoped>
.user_info_edire {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 999;
  transition: opacity 0.4s;
  background-color: rgba(0, 0, 0, 0.45);
  .edit_box {
    position: absolute;
    right: 0px;
    left: 0;
    top: 161px;
    width: 640px;
    height: 482px;
    opacity: 0;
    z-index: 99;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    transform: scale(0.1);
    // transform-origin: 150% 0%;
    transition: all 0.3s;
  }

  .edit_top_box {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px;
    border-bottom: 1px solid #e8e8e8;
    .el-icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      transform: rotate(0deg);
      cursor: pointer;
      transition: transform 0.3s;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  .edit_box_content {
    display: flex;
    width: 640px;
    height: 429px;
    background-color: #fff;
    .edit_box_content_left {
      width: 142px;
      border-right: 1px solid #e8e8e8;
      font-size: 16px;
      li {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
      }
      .is_option {
        background-color: #f2f5f7;
      }
    }
    .edit_box_content_right {
      width: 497px;
      padding: 20px;
    }
  }
}
</style>
