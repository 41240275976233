<template>
  <div class="user">
    <!-- 个人信息 -->
    <!-- 头部信息 -->
    <div class="user-top">
      <span class="biaoti head-text">头像</span>
      <!-- 头像展示 -->
      <el-image v-if="!isAlter" class="head-sculpture" :src="userInfo.header">
        <div slot="error" class="image-slot">
          <img class="head-sculpture" src="@/assets/img/tou.png">
        </div>
      </el-image>
      <!-- 头像上传 -->
      <el-upload v-else class="head-sculpture avatar-uploader" action="#" :show-file-list="false"
                 :on-success="handleAvatarSuccess" :on-change="upchange"
                 :http-request="handleUpload">
        <img v-if="imageUrl" :src="imageUrl" class="user-img">
        <img v-else
             :src="this.userInfo.header==''?require('@/assets/img/tou.png'):this.userInfo.header"
             class="user-img">
      </el-upload>
      <!-- 右侧操作按钮 -->
      <img v-show="isAlter" class="icon" @click="alter" src="@/assets/img/userInfo/xg.png">
      <div v-show="isAlter" @click="revert" class="revert">取消</div>
    </div>

    <div class="user-item">
      <!-- 用户数据展示 -->
      <div class="user-static">
        <ul class="user_info_ul">
          <li>
            <span class="biaoti">姓名</span>
            <i v-if="!isAlter">{{user.trueName || '未设置'}}</i>
            <el-input size="mini" clearable v-else v-model="user.trueName" placeholder="请输入内容">
            </el-input>
          </li>
          <li>
            <span class="biaoti">昵称</span>
            <i v-if="!isAlter">{{user.name || '未设置'}}</i>
            <el-input maxlength="10" size="mini" show-word-limit v-else v-model="user.name"
                      placeholder="请输入内容"></el-input>
          </li>
          <li>
            <span class="biaoti">身份</span>
            <i>{{identityType[user.identityType-1] || '未设置'}}</i>
          </li>
          <li>
            <span class="biaoti">性别</span>
            <!-- user.birthday === 1 -->
            <i v-if="!isAlter">{{issex}}</i>
            <el-select size="mini" v-else v-model="user.sex" placeholder="请选择">
              <el-option :disabled="item.value===2" v-for="item in sexList" :key="item.value"
                         :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li v-if="userInfoByType('医院')">
            <span class="biaoti">医院</span>
            <i v-if="!isAlter">{{user.hospital || '未设置'}}</i>
            <el-input size="mini" clearable v-else v-model="user.hospital" placeholder="请输入内容">
            </el-input>
          </li>
          <li v-if="userInfoByType('单位')">
            <span class="biaoti">单位</span>
            <i v-if="!isAlter">{{user.company || '未设置'}}</i>
            <el-input size="mini" clearable v-else v-model="user.company" placeholder="请输入内容">
            </el-input>
          </li>
          <template v-if="userInfoByType('学生')">
            <li>
              <span class="biaoti">学校</span>
              <i v-if="!isAlter">{{user.school || '未设置'}}</i>
            </li>
            <li>
              <span class="biaoti">专业</span>
              <i v-if="!isAlter">{{user.major || '未设置'}}</i>
            </li>
            <li>
              <span class="biaoti">学历</span>
              <i v-if="!isAlter">{{user.education || '未设置'}}</i>
            </li>
          </template>

          <li v-if="userInfoByType('科室')">
            <span class="biaoti">科室</span>
            <i v-if="!isAlter">{{user.departmentName || '未设置'}}</i>
            <el-cascader size="mini" v-else v-model="departmentName" :options="departmentList"
                         :show-all-levels="false" :props="props" @change="handleChange">
            </el-cascader>
          </li>
          <li v-if="userInfoByType('职称')">
            <span class="biaoti">职称</span>
            <i v-if="!isAlter">{{user.title || '未设置'}}</i>
            <el-select size="mini" v-else v-model="user.title" placeholder="请选择">
              <el-option v-for="item in titleList" :key="item.value" :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="biaoti">联系电话</span>
            <i v-if="!isAlter">{{user.phone || '未设置'}}</i>
            <!-- 联系电话不可修改 -->
            <el-input size="mini" disabled clearable v-else v-model="user.phone" @blur="phone"
                      placeholder="请输入内容"></el-input>
          </li>
          <li>
            <span class="biaoti">邮箱</span>
            <i v-if="!isAlter">{{user.email || '未设置'}}</i>
          </li>
          <li>
            <span class="biaoti">出生日期</span>
            <i v-if="!isAlter">{{user.birthday || '未设置'}}</i>
            <el-date-picker size="mini" v-else v-model="user.birthday" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
            </el-date-picker>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { setHeadPortrait, gainUserInfo, getDepartmentList, amendVerificationCode, amendName, amendSex, amendProfile } from '@/api/user.js'
export default {
  name: 'user',
  data () {
    return {
      user: {}, // 用户信息
      isAlter: false, // 修改状态
      imageUrl: '', // 上传图片路径
      userId: null, // 用户ID
      departmentList: [], // 科室数据
      departmentName: null, // 选中科室
      // 科室下拉菜单配置项
      props: {
        expandTrigger: 'hover',
        value: 'id',
        label: 'secondLevel'
      },
      // 职称列表
      titleList: [
        { value: '主任医师', label: '主任医师' },
        { value: '副主任医师', label: '副主任医师' },
        { value: '主治医师', label: '主治医师' },
        { value: '住院医师', label: '住院医师' },
        { value: '医学生', label: '医学生' }
      ],
      // 身份数据
      identityType: ['医生', '药师', '护士', '技师', '研究员', '教学', '学生', '企业'],
      // 性别数据
      sexList: [
        { value: 1, label: '男' },
        { value: 0, label: '女' },
        { value: 2, label: '请设置性别' }
      ]
    }
  },
  methods: {
    ...mapMutations('user', ['setuserInfo']),
    // 根据用户身份显示不同信息
    userInfoByType (type) {
      // 取反
      const map = {
        医院: [5, 6, 7, 8],
        单位: [0, 1, 2, 3, 4, 5, 6, 7],
        科室: [5, 6, 7, 8],
        职称: [7, 8],
        学生: [0, 1, 2, 3, 4, 5, 6, 8]
      }
      return !map[type].includes(this.user.identityType)
    },
    // 文件改变时触发
    upchange (file) {
      const isJPG = file.raw.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      if (isJPG && isLt2M) {
        // console.log(file)
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    // 自定义头像上传
    async handleUpload (file) {
      const isJPG = file.file.type === 'image/jpeg'
      const isLt2M = file.file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      } else if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      } else if (isJPG && isLt2M) {
        // 转换成表单形式 提交
        const fd = new FormData()
        fd.append('file0', file.file)
        fd.append('userId', this.userInfo.userId)
        // 请求 上传图片
        // // 查看fd对象
        // fd.forEach((item, key) => {
        //   console.log(key + ':' + item)
        // })
        try {
          await setHeadPortrait(fd)
          this.gainUserInfo()
          this.$message.success('头像上传成功')
        } catch (error) {
          return this.$message.error('上传头像失败')
        }
      }
      return isJPG && isLt2M
    },
    // 修改数据按钮状态
    alter () {
      this.isAlter = true
      this.getDepartmentList()
    },
    // 返回按钮状态
    revert () {
      this.isAlter = false
      this.gainUserInfo()
    },
    // 获取用户信息
    async gainUserInfo () {
      try {
        const res = await gainUserInfo({ userId: this.userId })
        // this.user = JSON.parse(JSON.stringify(res.data))
        if (res.data.birthday === '未设置') {
          this.user = {
            ...res.data,
            birthday: ''
          }
        } else {
          this.user = JSON.parse(JSON.stringify(res.data))
        }
        this.setuserInfo(res.data)
      } catch (error) {
        return this.$message.error('获取用户信息失败')
      }
    },
    // 获取科室列表
    async getDepartmentList () {
      try {
        const { data } = await getDepartmentList()
        const arr = Object.keys(data)
        const newarr = arr.map(item => {
          return {
            id: item,
            secondLevel: item,
            children: data[item]
          }
        })
        this.departmentList = newarr
      } catch (error) {
        return this.$message.error('获取科室列表失败')
      }
    },
    // 选中科室
    handleChange (val) {
      this.departmentName = val[1]
    },
    // 验证手机号码格式
    async phone (e) {
      if (e === '') return this.$message.warning('请填写手机号')
      const reg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (!reg.test(e)) return this.$message.warning('请正确填写手机号')
      const res = await amendVerificationCode({ phone: e })
      console.log(res)
    },
    // 修改用户数据提交按钮
    async preserve () {
      const userId = this.userId
      const name = await amendName({ userId, newName: this.user.name }) // 修改昵称
      const sex = await amendSex({ userId, sex: this.user.sex }) // 修改性别
      const hospital = this.alterProfile('hospital', this.user.hospital) // 修改单位
      const departmentName = this.departmentName && this.alterProfile('departmentId', this.departmentName) // 科室
      const trueName = this.alterProfile('trueName', this.user.trueName) // 修改真实姓名
      const title = this.alterProfile('title', this.user.title) // 修改职称
      const birthday = this.alterProfile('birthday', this.user.birthday) // 修改生日
      const arr = [name, sex, hospital, departmentName, trueName, title, birthday].filter(item => item)
      Promise.all(arr).then(result => {
        this.revert()
        this.gainUserInfo()
        this.$message.success('修改个人信息成功')
      }).catch(e => {
        this.$message.error('修改用户信息失败')
      })
    },
    // 处理接口数据
    async alterProfile (key, value) {
      if (!value) return
      switch (key) {
        case 'trueName':
          this.userInfo.trueName = value
          break
        case 'hospital':
          this.userInfo.hospital = value
          break
        case 'departmentId':
          this.userInfo.departmentName = value
          break
        case 'birthday':
          this.userInfo.birthday = value
          break
        case 'title':
          this.userInfo.title = value
          break
      }
      const data = {
        key,
        value,
        userId: this.userInfo.userId
      }
      const res = await amendProfile(data)
      return res
    },
    init () {
      this.userId = this.userInfo.userId
      this.gainUserInfo()
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    // 处理性别
    issex () {
      if (this.user.sex === 1) {
        return '男'
      } else if (this.user.sex === 0) {
        return '女'
      } else {
        return '未设置'
      }
    }
  },
  created () {
    this.userId = this.userInfo.userId
    this.gainUserInfo()
  }
}
</script>
<style lang="less" scoped>
.user {
  width: 100%;
  height: 500px;
  background-color: #fff;
  .user-top {
    height: 60px;
    margin-bottom: 22px;
    .head-text {
      float: left;
    }
    .head-sculpture {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .avatar-uploader {
      .el-upload {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .user-img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
    }

    .revert {
      float: right;
      width: 45px;
      height: 25px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      border-radius: 4px;
      border: 1px solid #4b639f;
    }
    .icon {
      float: right;
      width: 20px;
      height: 20px;
    }
  }
  .user-item {
    height: 100%;
    .user-static {
      height: 100%;
      .user_info_ul {
        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: 46px;
        grid-gap: 10px 20px;
        height: 100%;
        // i {
        //   margin-left: 28px;
        // }
        li {
          display: flex;
          align-items: center;
          height: 46px;

          i {
            font-size: 12px;
          }
        }
      }
      .el-input {
        width: 160px;
      }
      .el-cascader,
      .el-select {
        width: 160px;
      }
    }
  }
  // 鼠标hover公共样式
  .revert:hover,
  .icon:hover,
  .preservation:hover {
    cursor: pointer;
  }
  // 标题公共样式
  .biaoti {
    display: inline-block;
    // width: 60px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #4b639f;
  }
}
</style>
